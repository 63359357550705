import request from "@/common/request";
import { NotificacaoGlobal } from "@/models";
import { injectable } from "inversify-props";
import { NotificacaoGlobalServiceAdapter } from "@/usecases";

@injectable()
export class NotificacaoGlobalService implements NotificacaoGlobalServiceAdapter {
	static URL_BASE = '/notificacoes/global'

	async buscarNotificacaoAtiva() {
		return await request.get<never, NotificacaoGlobal>(NotificacaoGlobalService.URL_BASE)
	}

	async setarRecebido(notificacaoId: string) {
		return await request.post<void>(NotificacaoGlobalService.URL_BASE + `/${notificacaoId}`)
	}
}

export default new NotificacaoGlobalService()