import { VoucherServiceAdapter } from '@/usecases/'
import request from '@/common/request'
import { Page, Voucher } from '@/models'
import { AxiosRequestConfig } from 'axios'

const PATH = '/vouchers'
export class VoucherService implements VoucherServiceAdapter {
	async update(voucher: Voucher): Promise<Voucher> {
		return request.put(`${PATH}/${voucher.id}`, voucher)
	}

	async findVouchers(params: any, config?: AxiosRequestConfig): Promise<Page<Voucher>> {
		return request.get(`${PATH}`, { params, ...config })
	}

	async updateVoucherEmMassa(command: any): Promise<void> {
		return request.post(`${PATH}/alterarcao-em-massa`, command )
	}

	async dobraVoucherEmMassa(command: any): Promise<void> {
		return request.post(`${PATH}/dobra-em-massa`, command)
	}

	async disparoDeMensagemVoucher(command: any): Promise<void> {
		return request.post(`${PATH}/disparo-mensagem-voucher`, command)
	}

	async updateCancelaDisparoMensagem(command: any): Promise<void> {
		return request.post(`${PATH}/cancela-disparo-mensagem`, command)
	}
}
