import request from '@/common/request'
import { PontoDeVenda, PontoDeVendaComTurno } from '@/models'

export const editarSituacaoPdv = (pdvId: string, aberto: boolean, encerrarTurno: boolean | null): Promise<PontoDeVenda> =>
	request.patch(`/pdvs/${ pdvId }/abrir-fechar/encerrar-turno/${encerrarTurno}`, {
		pdvId,
		aberto,
	})

export const findUltimoUsuarioLogado = (pdvId: string, operadorId: string): Promise<boolean> => 
	request.get(`/pdvs/${pdvId}/operador/${operadorId}`)

export const setarUsuarioLogado = (pdvId: string, operadorId: string): Promise<number> =>
	request.put(`/pdvs/${pdvId}/seta-usuario-logado/${operadorId}`, {pdvId, operadorId})

export const buscarPdvSemTurno = (lojaId?: string): Promise<PontoDeVendaComTurno[]> =>
	request.get(`/pdvs/pdv-aberto/${lojaId}`);
	
