import { PontoDeVenda } from "@/models";
import { Inject } from "inversify-props";
import type { PdvServiceAdapter } from "./adapter/PdvServiceAdapter";

export class EditarPdvUseCase {

	@Inject("PdvServiceAdapter")
	private pdvService!: PdvServiceAdapter

	async editarSituacaoPdv(pdvId: string, aberto: boolean, encerrarTurno: boolean | null): Promise<PontoDeVenda> {
		return this.pdvService.editarSituacaoPdv(pdvId, aberto, encerrarTurno)
	}

	async findUltimoUsuarioLogado(pdvId: string, operadorId: string): Promise<boolean> {
		return this.pdvService.findUltimoUsuarioLogado(pdvId, operadorId)
	}

	async setarUsuarioLogado(pdvId: string, operadorId: string): Promise<number> {
		return this.pdvService.setarUsuarioLogado(pdvId, operadorId)
	}
}