import request from '@/common/request'
import { Deposito, Page, Estoque, Produto, FormDeItemDeEstoque, EstoqueProjection, ImportacaoCsvEstoque } from '@/models'
import { ItemDeRomaneio } from '@/models/Romaneio'
import { FormDeConversaoDeEstoque } from '@/models/estoque/ConversaoDeEstoque'
import { AxiosRequestConfig } from 'axios'

const basePath = '/depositos'

export const create = async (data: Deposito): Promise<Deposito> =>
	request.post(`${basePath}`, data)

export const update = async (data: Deposito): Promise<Deposito> =>
	request.put(`${basePath}/${data.id}`, data)

export const find = (params?: any): Promise<Page<Deposito>> =>
	request.get(`${basePath}`, {
		params: {
			...params,
		},
	})

export const get = async (id: string): Promise<Deposito> =>
	request.get(`${basePath}/${id}`)

export const remove = async (id: string): Promise<Deposito> =>
	request.delete(`${basePath}/${id}`)

export const findProdutosDeposito = (
	idDeposito: string,
	params?: any,
): Promise<Produto[]> =>
	request.get(`${basePath}/produto/${idDeposito}`, {
		params: {
			...params,
		},
	})

export const patch = async (estoque: Deposito): Promise<Deposito> =>
	request.patch(`${basePath}/${estoque.id}`, estoque)

// Estoque =>
export const createEstoque = async (
	idDeposito: string,
	data: FormDeItemDeEstoque,
): Promise<Estoque> => request.post(`${basePath}/adicionar/${idDeposito}`, data)

export const adicionarQtdeEstoque = async (
	idEstoque: string,
	qtde: number,
	observacao: string,
): Promise<Estoque> =>
	request.patch(`${basePath}/adicionar/${idEstoque}`, { qtde: qtde }, {
		params: {
			observacao,
		},
	})

export const alterarEstoqueMinimo = async (
	idEstoque: string,
	qtdeEstoqueMinimo: EstoqueProjection,
): Promise<Estoque> =>
	request.patch(`${basePath}/estoqueMinimo/${idEstoque}`, qtdeEstoqueMinimo)

export const retirarQtdeEstoque = async (
	idEstoque: string,
	qtde: number,
	observacao: string,
): Promise<Estoque> =>
	request.patch(`${basePath}/retirar/${idEstoque}`, { qtde: qtde }, {
		params: {
			observacao,
		},
	})

export const empenharEstoque = async (
	idEstoque: string,
	qtde: number,
	observacao: string,
): Promise<Estoque> =>
	request.patch(`${basePath}/empenhar/${idEstoque}`, { qtde: qtde }, {
		params: {
			observacao,
		},
	})

export const reaverEstoque = async (
	idEstoque: string,
	qtde: number,
	observacao: string,
): Promise<Estoque> =>
	request.patch(`${basePath}/reaver/${idEstoque}`, { qtde: qtde }, {
		params: {
			observacao,
		},
	})

export const deletarEstoque = async (
	idDeposito: string,
	idEstoque: string,
): Promise<void> =>
	request.delete(`${basePath}/${idDeposito}/estoques/${idEstoque}`)

export const montarEstoque = async (
	idDeposito: string,
	conversaoForm: FormDeConversaoDeEstoque,
): Promise<Deposito> =>
	request.put(`${basePath}/${idDeposito}/montar`, conversaoForm)

export const desmontarEstoque = async (
	idDeposito: string,
	conversaoForm: FormDeConversaoDeEstoque,
): Promise<Deposito> =>
	request.put(`${basePath}/${idDeposito}/desmontar`, conversaoForm)

export const buscarEstoques = async (
	params?: any,
	config?: AxiosRequestConfig,
): Promise<Page<Estoque>> => request.get(`${basePath}/estoques`, {
	params,
	...config,
})
export const getEstoque = async (idDeposito: string, idEstoque: string): Promise<Estoque> =>
	request.get(`${basePath}/${idDeposito}/estoques/${idEstoque}`)

export const encontrarProdutoComEstoque = async (identificador: string, loja: string): Promise<Produto> =>
	request.get(`${basePath}/produtos/${identificador}`, {
		params: {
			loja,
		},
	})

export const validarProduto = async (identificador: string, loja: string, quantidade: number): Promise<Produto> =>
	request.get(`${basePath}/produtos/${identificador}`, {
		params: {
			loja,
			quantidade,
		},
	})

export const encontrarProdutoComEstoqueRomaneio = async (identificador: string, loja: string, quantidade: number, tipoDeMovimentacao: string): Promise<Produto> =>
	request.get(`${basePath}/produtos/${identificador}/${quantidade}`, {
		params: {
			loja,
			tipoDeMovimentacao,
		},
	})


export const getEstoqueDoItemDaVendaNaLoja = async (
	idProduto: string,
	quantidadeDeItens: string,
	idLoja: string,
	autenticadoParaLiberarProdutoSemEstoque: boolean,
): Promise<Estoque> =>
	request.get(`${basePath}/loja/itens/${quantidadeDeItens}/${idProduto}`, {
		params: {
			idLoja,
			autenticadoParaLiberarProdutoSemEstoque,
		},
	})

export const importaItens = async (
	idDeposito: string,
	params?: ImportacaoCsvEstoque,
): Promise<void> =>
	request.post(`${basePath}/${idDeposito}/itens-em-bloco`, params)

export const exportEstoque = async (
	idTabela: string,
	layoutExportado: string,
): Promise<any> =>
	request.get(`${basePath}/${idTabela}/${layoutExportado}/all-itens`)

export const montarItens = async (
	urlDoArquivoCsv: string,
): Promise<ItemDeRomaneio[]> =>
	request.post(`${basePath}/romaneio/montar-itens`, urlDoArquivoCsv)

export const findAllByDeposito = async (
	depositoId: string,
	dataDoInventario: string,
): Promise<EstoqueProjection[]> =>
	request.get(`estoques/${depositoId}/listagem-completa?dataDoInventario=${dataDoInventario}`)

export const validaEstoqueNegativando = async (identificadores: string[], loja: string, quantidades: number[]): Promise<Produto> =>
	request.get(`${basePath}/deposito-negativando`, {
		params: {
			identificadores,
			loja,
			quantidades,
		},
	})