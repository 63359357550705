import { injectable } from 'inversify-props'
import { TurnoDeVendaServiceAdapter } from '@/usecases'
import {
	create,
	createMovimentacao,
	updateMovimentacao,
	fechamento,
	find,
	get,
	podeAbrirCaixa,
} from '@/api/turnodevenda'
import { FormTurnoDeVenda, MovimentacaoDeCaixa, FechamentoDeCaixa, TurnoDeVenda } from '@/models'
import request from '@/common/request';

@injectable()
export class TurnoDeVendaService implements TurnoDeVendaServiceAdapter {
	get = async (id: string) => await get(id)
	find = async (params?: any) => await find(params)
	create = async (form: FormTurnoDeVenda) => await create(form)
	createMovimentacao = async (
		idTurno: string,
		movimentacao: MovimentacaoDeCaixa,
	) => await createMovimentacao(idTurno, movimentacao)

	updateMovimentacao = async (
		idTurno: string,
		idMovimentacao: string,
		movimentacao: MovimentacaoDeCaixa,
	) => await updateMovimentacao(idTurno, idMovimentacao, movimentacao)

	fechamento = async (idTurno: string, fechamentoDeCaixa: FechamentoDeCaixa) =>
		await fechamento(idTurno, fechamentoDeCaixa)

	async getFechamentoEsperado(idDoTurnoDeVenda: string): Promise<FechamentoDeCaixa> {
		return request.get(`/turno-venda/${idDoTurnoDeVenda}/infos-fechamento`)
	}

	podeAbrirCaixa = async (pdvId: string) => await podeAbrirCaixa(pdvId)

	async getMovimentacoesCaixaByTurnoId(idDoTurnoDeVenda: string): Promise<MovimentacaoDeCaixa[]> {
		return request.get(`/turno-venda/${idDoTurnoDeVenda}/movimentacoes`)
	}
	
	async findTurnoDeVenda(pdvId: string): Promise<TurnoDeVenda> {
		return request.get(`/turno-venda/${pdvId}/buscar`)
	}
}
