import { Module, VuexModule, Mutation, getModule, Action } from 'vuex-module-decorators'
import { AxiosError } from 'axios'
import store from '@/store/vuex/index'
import UserLoginStore from '../authentication/UserLoginStore'
import NotificacaoGlobalService from '@/services/usuario/NotificacaoGlobalService'

@Module({
	name: 'alert-global',
	store,
	dynamic: true,
	namespaced: true,
})
class AlertaDeSistemaModule extends VuexModule {
	message: Message = {
		id: '',
		titulo: '',
		text: '',
		link: undefined,
	}
	mostrarAlertaDeSistema = false

	@Mutation
	setShow(mostrarAlertaDeSistema: boolean) {
		this.mostrarAlertaDeSistema = mostrarAlertaDeSistema
	}

	@Mutation
	setMessage(message: Message) {
		this.message = message
		this.mostrarAlertaDeSistema = true
	}

	get getErrorMsg() {
		return this.message.text
	}

	@Action
	async atualizarNotificacoes() {
		const idUsuario = UserLoginStore.usuario?.id
		if (!idUsuario) return

		const notificacaoGlobal = await NotificacaoGlobalService.buscarNotificacaoAtiva()

		if (!notificacaoGlobal) return

		const mensagemFormatada = {
			text: '',
			link: undefined,
			titulo: 'Nova Atualização',
		} as Message

		mensagemFormatada.text = notificacaoGlobal.descricao
		mensagemFormatada.titulo = notificacaoGlobal.titulo
		mensagemFormatada.id = notificacaoGlobal.id
		if (notificacaoGlobal.link) mensagemFormatada.link = notificacaoGlobal.link

		this.setMessage(mensagemFormatada)
	}
}

export type Message = {
	id: string
	titulo: string
	text: string
	timeout?: number
	link?: string
}

export type SetMessageParams = string | {
	text: string
	timeout?: number
}

export type ErroComPrefixo = {
	error: SetMessageParams | unknown
	prefixo: string | null
}

export type SetErrorMessageParams = string | AxiosError | {
	text: string | AxiosError
	timeout?: number
}

export default getModule(AlertaDeSistemaModule)