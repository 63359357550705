import { injectable } from "inversify-props";
import { editarSituacaoPdv, findUltimoUsuarioLogado, setarUsuarioLogado, buscarPdvSemTurno } from '@/api/pdv'
import { PdvServiceAdapter } from "@/usecases";
import { PontoDeVenda, PontoDeVendaComTurno } from "@/models";

@injectable()
export class PdvService implements PdvServiceAdapter {

	editarSituacaoPdv = async (pdvId: string, aberto: boolean, encerrarTurno: boolean | null): Promise<PontoDeVenda> =>
		await editarSituacaoPdv(pdvId, aberto, encerrarTurno)

	findUltimoUsuarioLogado = async (pdvId: string, operadorId: string): Promise<boolean> =>
		await findUltimoUsuarioLogado(pdvId, operadorId) 

	setarUsuarioLogado = async (pdvId: string, operadorId: string): Promise<number> =>
		await setarUsuarioLogado(pdvId, operadorId) 

	buscarPdvSemTurno = async (lojaId: string): Promise<PontoDeVendaComTurno[]> =>
		await buscarPdvSemTurno(lojaId)
}