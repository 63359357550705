import { Inject } from 'inversify-props'
import type { TurnoDeVendaServiceAdapter } from '.'
import { TurnoDeVenda, FechamentoDeCaixa } from '@/models'

export class FecharCaixaUseCase {
	@Inject('TurnoDeVendaServiceAdapter')
	private turnoService!: TurnoDeVendaServiceAdapter

	execute = async (
		turnoId: string,
		fechamento: FechamentoDeCaixa,
	): Promise<TurnoDeVenda> => {
		return await this.turnoService.fechamento(turnoId, fechamento)
	}
	
	findTurnoDeVenda = async (pdvId: string): Promise<TurnoDeVenda> => {
		return this.turnoService.findTurnoDeVenda(pdvId);
	}
}
